import React from "react";
import {Link} from "react-router-dom";


export const TeamCard = ({name, specialty, imageUrl, onClick, url}) => {
    return (

        <div onClick={onClick} className="team-member-card">

            <img className="team-card-img" src={imageUrl} alt={imageUrl} loading="lazy"/>
            <div className="team-member-card-name"><h2 style={{all: "unset"}}><Link to={url} style={{
                textDecoration: "none",
                color: "inherit",
                all: "unset"
            }}>{name}</Link></h2></div>
            <div className="team-member-card-specialty"><h3 style={{all: "unset"}}>{specialty}</h3></div>


        </div>
    );
};
export default TeamCard;
