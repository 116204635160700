import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Page} from "../../components/page";
import {useNavigate, useParams} from "react-router-dom";
import {team} from "../../shared/configs/team.config";
import Button from "../../shared/components/button";
import {bookVisitLink} from "../../constants";

export const TeamMember = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {key} = useParams();
    const [teams, setTeam] = useState(null);

    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };

    useEffect(() => {
        const foundTeam = team.find(el => el.key === key);
        setTeam(foundTeam);
    }, [key]);
    return (
        <Page>

            <div className="team-wrapper full-width-content">
                <div className="team-left-column">
                    <div>
                        {teams ? (
                            <>
                                <h1 className="team-item-title">{t(`team.${teams.key}.name`)}</h1>
                                <h2 style={{all:"unset"}}><p className="team-item-des">{t(`team.${teams.key}.specialty`)}</p></h2>
                            </>
                        ) : (
                            navigate('/404', {replace: true})
                        )}
                    </div>
                </div>
                <div className="right-column">
                    {teams ? (
                        <img src={teams.image} alt="teams.image"/>
                    ) : (
                        navigate('/404', {replace: true})
                    )}
                </div>
            </div>

            <div className="page-container team-des-offered">
                <div className="team-list-grid ">
                    <div className="team-list-grid-item">
                        {teams ? (
                            <>
                                <div
                                    dangerouslySetInnerHTML={{__html: t(`team.${teams.key}.des`)}}
                                />

                                {showMore && (
                                    <div
                                        dangerouslySetInnerHTML={{__html: t(`team.${teams.key}.des2`)}}
                                    />
                                )}

                                {!showMore && t(`team.${teams.key}.des2`) !== `team.${teams.key}.des2` && (
                                    <a className="device-read-more" onClick={handleToggle}>{t('common.read_more')}</a>
                                )}
                            </>
                        ) : (
                            navigate('/404', {replace: true})
                        )}

                    </div>


                </div>
                <div className="row book-visit-btn">
                    <Button onClick={() => window.open(bookVisitLink)}>
                        {t('common.book_visit')}
                    </Button>
                </div>
            </div>

        </Page>
    )
}