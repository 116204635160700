import React from "react";
import {Link} from "react-router-dom";


export const CosmetologyDeviceCard = ({caption, title, imageUrl, onClick, url}) => {
    return (

        <div onClick={onClick} className="cosmetology-device-card">

            <img className="cosmetology-device-card-img" src={imageUrl} alt={imageUrl} loading="lazy"/>

            <div className="cosmetology-device-card-caption"><h3 style={{all: "unset"}}>{caption}</h3></div>
            <div className="cosmetology-device-card-title"><h2 style={{all: "unset"}}><Link to={url} style={{
                textDecoration: "none",
                color: "inherit",
                all: "unset"
            }}>{title} </Link></h2></div>


        </div>

    );
};
export default CosmetologyDeviceCard;
