import React from "react";

export const ServiceCard = ({caption, imageUrl, onClick,url}) => {
    return (
        // <a href={{onClick}}>
        //     <div onClick={onClick} className="service-card"
        //          style={{'backgroundImage': `url(${imageUrl})`}}>
        //         <div className="service-card-caption"><h3 style={{all: 'unset'}}>{caption}</h3></div>
        //     </div>
        // </a>

        <div className="service-card" style={{ backgroundImage: `url(${imageUrl})` }}>
            <a
                href={url}
                onClick={(e) => {
                    e.preventDefault(); // Запобігаємо переходу за замовчуванням
                    onClick?.(); // Викликаємо функцію кліку
                }}
                style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    textDecoration: "none",
                }}
            >
                <div className="service-card-caption">
                    <h3 style={{ all: "unset" }}>{caption}</h3>
                </div>
            </a>
        </div>

    );
};
export default ServiceCard;
