import React from 'react';
import { useTranslation } from 'react-i18next';
import ServiceCard from './service-card';
import {Link,useNavigate} from "react-router-dom";

const ServiceList = ({data}) => {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const onServiceCardClick = (key) => {
        navigate(`/services/${key}`)
    };
    return (
        <div className="service-list">
            {data?.map((service, index) => (
                <ServiceCard
                    // url={navigate(`/services/${service.key}`)}
                onClick={() => onServiceCardClick(service.key)}
                key={service.key || index}
                url={`/services/${service.key}`}
                caption={t(`services.${service.key}.title`)}
                imageUrl={service.image}
                />


            ))}
        </div>
    );
};

export default ServiceList;
