import React, { useContext } from 'react';
import Button from '../../../shared/components/button';
import { useTranslation } from 'react-i18next';
import { ResizeContext } from '../../../context/resize-context';
import { bookVisitLink } from '../../../constants';
import CosmetologyDeviceCard from '../../../shared/components/cosmetology-device-card';
import { devices } from '../../../shared/configs/cosmetology-devices.config';
import { useNavigate } from 'react-router-dom';
import ServiceCard from "../../../shared/components/service-card";
import TeamCard from "../../../shared/components/team-card";

const AllCosmetologyDevices = () => {
    const {t} = useTranslation();
    const {isTablet} = useContext(ResizeContext);
    const navigate = useNavigate();

    const data = devices;

    const onDeviceCardClick = (key) => {
        navigate(`/device/${key}`)
    };
    return (
        <section className="all-cosmetology-devices">
            <p className="section-description">
                {t('devices.description')}
            </p>
            <div className="cosmetology-devices-list">
                {data?.map((device, index) => (
                    <CosmetologyDeviceCard key={device.key || index}
                                           imageUrl={device.image}
                                           url={`/device/${device.key}`}
                                           onClick={() => onDeviceCardClick(device.key)}
                                           title={t(`devices.${device.key}.title`)}
                                           caption={t(`devices.${device.key}.caption`)}
                    />
                ))}
            </div>
            <div className="row book-visit-btn">
                <Button onClick={() => window.open(bookVisitLink)}>
                    {t('common.book_visit')}
                </Button>
            </div>
        </section>
    )
}
export default AllCosmetologyDevices;