import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ResizeContext} from '../../../context/resize-context';
import post1 from '../../../assets/instagram/post-1.jpg';
import post2 from '../../../assets/instagram/post-2.jpg';
import post3 from '../../../assets/instagram/post-3.jpg';
import post4 from '../../../assets/instagram/post-4.jpg';
import post5 from '../../../assets/instagram/post-5.jpg';
import post6 from '../../../assets/instagram/post-6.jpg';

import '../../../styles/components/instagram.css'
import {instagramLink} from '../../../constants';

const Posts = [post1, post2, post3, post4, post5, post6];

export const Instagram = () => {
    const {t} = useTranslation();
    const {width, isTablet} = useContext(ResizeContext);
    const [posts, setPosts] = useState(Posts);

    return (
        <section className="main-instagram-section page-container">
            <a href={instagramLink} target="_blank">
                <h2 className="section-title">
                    {t('instagram.title')}
                </h2>
            </a>
            {width > 991 && <p className="section-description">
                {t('instagram.description')}
            </p>}
            {isTablet ? (
                <div className="post-grid">
                    {posts.map((post, index) => (
                        <img key={index} onClick={() => window.open(instagramLink)} src={post} alt={post} loading="lazy"/>))}
                </div>) : (
                <div className="post-grid">
                    {posts.slice(0, 4).map((post, index) => (
                        <img key={index} onClick={() => window.open(instagramLink)} src={post} alt={post} loading="lazy"/>))}
                </div>
            )}
        </section>
    )
}