import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ResizeContext } from '../../context/resize-context';

const ServiceFilter =({filters, activeFilter, onValueChange}) => {
    const { t } = useTranslation();
    const {isTablet} = useContext(ResizeContext);

    const onFilterValueChange = (service) => {
        onValueChange(service);
    };

    return (
        <div className={`service-filter hide-scrollbar ${isTablet && 'full-width-content service-filter-mobile'}`}>
            {filters?.map((service, index) => (
                <div
                    onClick={() => onFilterValueChange(service)}
                    className={`filter-button ${activeFilter === service && 'active'}`}
                    key={index}
                ><h2 style={{all: 'unset'}}>
                    {t(`services.filters.${service}`)}
                </h2>
                </div>
            ))}
        </div>
    );
}

export default ServiceFilter;
