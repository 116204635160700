import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ResizeContext } from '../../../context/resize-context';
import '../../../styles/components/reviews.css'

import review1 from '../../../assets/reviews/review-1.jpg';
import review2 from '../../../assets/reviews/review-2.jpg';
import review3 from '../../../assets/reviews/review-3.jpg';
import review4 from '../../../assets/reviews/review-4.jpg';
import review5 from '../../../assets/reviews/review-5.jpg';
import review1pl from '../../../assets/reviews/review-1pl.jpg';
import review2pl from '../../../assets/reviews/review-2pl.jpg';
import review3pl from '../../../assets/reviews/review-3pl.jpg';
import review4pl from '../../../assets/reviews/review-4pl.jpg';
import review5pl from '../../../assets/reviews/review-5pl.jpg';

import { Slide } from 'react-slideshow-image';
import sliderArrowLeft from '../../../assets/icons/arrow-left.svg';
import reviewIcon from '../../../assets/icons/reviews.svg';
import { getAdaptiveSlidesCount } from '../../../utils';


const ReviewList = [review1, review2, review3, review4, review5];
const ReviewListPl = [review1pl, review2pl, review3pl, review4pl, review5pl];

export const Reviews = () => {
    const {t, i18n} = useTranslation();
    const {isTablet} = useContext(ResizeContext);
    const [reviews, setReviews] = useState([...ReviewList, ...ReviewList]);

    const customArrowPreviewComponent = <div className='slider-navigation'><img src={sliderArrowLeft} alt="chevron"/>
    </div>;


    const slideWidth = 352 + 16;
    const sliderProperties = {
        autoplay: false,
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
        responsive: [
            getAdaptiveSlidesCount(1800, slideWidth),
            getAdaptiveSlidesCount(1440, slideWidth),
            getAdaptiveSlidesCount(1360, slideWidth),
            getAdaptiveSlidesCount(1200, slideWidth),
            getAdaptiveSlidesCount(991, slideWidth),
            getAdaptiveSlidesCount(600, slideWidth),
        ]
    }

    let currentReviewList = i18n.language === 'pl' ? ReviewListPl : ReviewList;

    return (
        <section className="main-reviews-section full-width-content">
            <div className="page-container reviews-info-block">
                <div className="estimation-block">
                    <span>4.7</span>
                    <img src={reviewIcon} alt={reviewIcon}/>
                </div>
                <h2 className="section-title">
                    {t('reviews.title')}
                </h2>
            </div>
            <div className="reviews-slider-container">
                <Slide {...sliderProperties}>
                    {currentReviewList.map((review, index) => (
                        <img  className="review-item" key={index} src={review} alt={review} loading="lazy"/>))}
                </Slide>
            </div>
        </section>
    )
}